"use client";

import Text from "@/lib/components/Text";
import Button from "@/lib/components/Button";
import CloudImage from "@/lib/components/CloudImage";
import SearchBar from "@/lib/components/SearchBar";
import { Cloudinary } from "@/lib/constants/cloudinary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    AnonymousUserMenuEntries,
    GuestPanelMenuEntries,
    MENU_SHOW_INPUT_THRESHOLD,
    MenuEntries,
    MenuEntry,
} from "@/lib/constants/menuEntries";
import { PageType } from "@/lib/types/Page";
import { LanguageParams } from "@/lib/types/PageProps";
import { useClickAway, useWindowScroll } from "@uidotdev/usehooks";
import { ReactNode, useEffect, useRef, useState } from "react";
import MenuItem from "./MenuItem";
import { useTranslation } from "@/lib/i18n/client";
import { getUrl, Urls } from "@/lib/constants/urls";
import LanguageSelector from "../LanguageSelector";
import { faAt, faBars, faCaretDown, faClose, faMagnifyingGlass, faQuestion } from "@fortawesome/pro-solid-svg-icons";
import { Transition } from "@headlessui/react";
import { getLanguageData } from "@/lib/i18n/settings";
import LanguageSelectorIcon from "../LanguageSelector/LanguageSelectorIcon";
import UserMenu from "./UserMenu";
import Input from "../Input";
import Link from "next/link";
import GenericMenuItem from "../GenericMenuItem/GenericMenuItem";
import { useAtom, useSetAtom } from "jotai";
import { authAtom, isLoginModalShownAtom } from "@/lib/jotai/auth/authStore";
import { usePathname, useSearchParams } from "next/navigation";
import { userLogOut } from "@/lib/utils/logout";
import { classNames } from "@/lib/utils/general";
import { extractSearchUrlParameters } from "@/lib/utils/search";
import { useTailwindBreakpoint } from "@/lib/hooks/useTailwindBreakpoint";
import { breakpointIsLessThan } from "@/lib/utils/tailwind";
import UserProfilePicture from "../UserProfilePicture";
import { useRegisterNextParams } from "@/lib/hooks/useRegisterNextParams";
import { faCircleUser } from "@fortawesome/pro-light-svg-icons";
import { GoogleTagManagerEvents } from "@/lib/utils/googleTagEvents";

type Props = {
    pageType?: PageType;
    isGuestPanelNavigation?: boolean;
} & LanguageParams;

const MenuClient = ({ lang, pageType = "default", isGuestPanelNavigation = false }: Props) => {
    const [{ y }] = useWindowScroll();
    const refHeader = useRef(null);
    const [auth, setAuth] = useAtom(authAtom);
    const userIdForGTM = auth.isLoggedIn ? auth.userInfo._id : undefined;
    const currentPath = usePathname();
    const [transparent, setTransparent] = useState(pageType === "home");
    const openLoginModal = useSetAtom(isLoginModalShownAtom);
    const [showSearchInput, setShowSearchInput] = useState(pageType !== "home");
    const [showSearchBar, setShowSearchBar] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [mobileMenuType, setMobileMenuType] = useState<"menu" | "user">("menu");
    const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false);
    const [isMobileLanguageOpen, setIsMobileLanguageOpen] = useState(false);
    const refMenuButton = useRef<HTMLDivElement>(null);
    const refUserMenuButton = useRef<HTMLDivElement>(null);
    const currentLangaugeData = getLanguageData(lang);
    const { t } = useTranslation(lang, "menu");
    const { t: tSearch } = useTranslation(lang, "search");
    const { t: tGeneral } = useTranslation(lang);
    const searchUrlParams = useSearchParams();
    const [searchBarProps] = useState(extractSearchUrlParameters(searchUrlParams));
    const [, screenSize] = useTailwindBreakpoint();
    const isMobile = breakpointIsLessThan(screenSize, "lg");
    const isHideShadow = (pageType === "search" && isMobile) || transparent;
    const [prevY, setPrevY] = useState(0);
    const pathname = usePathname();
    const registerParams = useRegisterNextParams();

    useEffect(() => {
        if (pageType === "home") {
            const header = refHeader.current as unknown as HTMLElement;
            const newTransparent = (y as number) <= header.clientHeight;

            // only set value if it changed
            if (newTransparent !== transparent) {
                setTransparent(newTransparent);
            }
        }

        const newShowSearchInput = (y as number) > MENU_SHOW_INPUT_THRESHOLD; // we could get position of search bar here and display input only when search bar is not shown
        if (!isMobile) {
            // only set value if it changed
            if (newShowSearchInput !== showSearchInput) {
                if (newShowSearchInput === false && pageType === "home") {
                    setShowSearchInput(newShowSearchInput);
                } else {
                    setShowSearchInput(true);
                }
                // also close search if we go above threshold
                if (!newShowSearchInput && showSearchBar) {
                    setShowSearchBar(false);
                }
            }
        } else {
            const isScrollUp = (y as number) !== 0 && prevY > (y as number);
            if (
                (pageType !== "home" && (y as number) === 0) ||
                (pageType === "home" && !newShowSearchInput) ||
                (pageType !== "home" && isScrollUp)
            ) {
                setShowSearchInput(false);
            } else {
                setShowSearchInput(true);
            }
            setPrevY(y as number);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile, y, pageType]);

    useEffect(() => {
        // Close the menu every time the route changes
        setShowMobileMenu(false);
    }, [currentPath]);

    const handleShowSearchBar = () => {
        setShowSearchBar(true);
    };

    const handleCloseSearchBar = () => {
        setShowSearchBar(false);
    };

    const handleToggleMobileMenu = (type: "menu" | "user") => {
        setMobileMenuType(type);
        if (type !== mobileMenuType) {
            if (!showMobileMenu) {
                setShowMobileMenu(true);
            }
        } else {
            setShowMobileMenu(!showMobileMenu);
        }
    };

    const handleOpenSearchModal = () => {
        setShowMobileMenu(false);
        setIsMobileSearchOpen(true);
    };

    const closeMenu = (event: Event) => {
        const node = event.target as Node;
        if (!refMenuButton.current?.contains(node) && !refUserMenuButton.current?.contains(node)) {
            setShowMobileMenu(false);
        }
    };
    const refMenu = useClickAway(closeMenu);

    const handleOpenLanguageModal = () => {
        setShowMobileMenu(false);
        setIsMobileLanguageOpen(true);
    };

    let registerUrl = getUrl(Urls.register.index, lang);
    if (registerUrl !== pathname) {
        registerUrl = getUrl(Urls.register.index, lang, null, registerParams);
    }

    /**
     * Returns the menu according to component configuration
     * default is AnonymousUserMenuEntries
     *
     * @returns (MenuEntry | MenuEntry[])[]
     */
    const fetchMobileMenu = (): (MenuEntry | MenuEntry[])[] => {
        if (isGuestPanelNavigation || auth.isLoggedIn) {
            const guestPaneMenuWithLogout = GuestPanelMenuEntries.map((el) => {
                if ((el as MenuEntry).key === "Log out") {
                    (el as MenuEntry).onClickHandler = () => {
                        setShowMobileMenu(false);
                        userLogOut(setAuth, auth);
                    };
                }
                return el;
            });
            return guestPaneMenuWithLogout;
        }
        if (mobileMenuType === "menu") {
            const modifiedMenuEntries = MenuEntries.map((el) => {
                if ((el as MenuEntry).key === "faq") {
                    (el as MenuEntry).onClickHandler = () =>
                        GoogleTagManagerEvents.clickFAQorBecomeHostorContact(lang, "faq", userIdForGTM);
                }
                if ((el as MenuEntry).key === "contact-us") {
                    (el as MenuEntry).onClickHandler = () =>
                        GoogleTagManagerEvents.clickFAQorBecomeHostorContact(lang, "contact", userIdForGTM);
                }
                return el;
            });
            return modifiedMenuEntries;
        }
        return AnonymousUserMenuEntries;
    };

    /**
     *
     * @param condition
     * @returns string|" text-white "|" text-black "
     */
    const getIconColor = (condition: boolean): string => {
        let colorClass = " text-black ";
        if (condition) {
            colorClass = " text-white ";
        }
        return colorClass;
    };

    /**
     *
     * @param condition boolean
     * @param height number
     * @param width number
     * @returns <CloudImage/>
     */
    const getAppLogo = (condition: boolean, height: number, width: number): ReactNode => {
        return (
            <CloudImage
                src={condition ? Cloudinary.menu.logoWhite : Cloudinary.menu.logoOrange}
                alt={tGeneral("title")}
                height={height}
                width={width}
                svg
            />
        );
    };

    const fetchInitials = () => {
        const firstInitial = Array.from(auth.userInfo.firstName ?? "")[0] ?? "";
        const lastInitial = Array.from(auth.userInfo.lastName ?? "")[0] ?? "";
        return firstInitial + lastInitial;
    };

    return (
        <header
            ref={refHeader}
            className={`fixed z-20 top-0 left-0 w-full lg:px-12 transition-colors ease-in duration-200 ${transparent ? "text-white" : "text-black bg-white"
            }
                ${isGuestPanelNavigation ? "lg:bg-black text-white" : ""} ${isHideShadow ? "" : "shadow-md"}`}
        >
            {/* DESKTOP MENU */}
            <div
                className={`hidden lg:flex gap-12 items-center justify-between font-bold w-full h-full ${isGuestPanelNavigation ? "lg:bg-black text-white" : ""
                }`}
            >
                <Link
                    href={getUrl(Urls.home.index, lang)}
                    className={`hidden lg:block flex-none ${transparent ? "" : "text-primary-500"}`}
                >
                    {getAppLogo(transparent || isGuestPanelNavigation, 33, 126)}
                </Link>
                {pageType !== "search" && showSearchInput && (
                    <div className="flex-grow relative">
                        {showSearchBar ? (
                            tSearch("where-to-go")
                        ) : (
                            <Input
                                icon={faCaretDown}
                                iconDirection="right"
                                value={tSearch("where-to-go") ?? ""}
                                wrapperClassName="w-full max-w-xs"
                                onFocus={handleShowSearchBar}
                                readOnly
                            />
                        )}
                    </div>
                )}

                <div className="text-right flex gap-12 h-full">
                    <LanguageSelector
                        lang={lang}
                        dark={!transparent}
                        onCloseMobileModal={() => setIsMobileLanguageOpen(false)}
                        isMobileModalOpen={isMobileLanguageOpen}
                    />
                    {MenuEntries.map((value: unknown) => {
                        const key = Array.isArray(value) ? value[0].key : (value as MenuEntry).key;
                        const isFAQ = (value as MenuEntry)?.key === "faq";
                        (value as MenuEntry).onClick = () => {
                            GoogleTagManagerEvents.clickFAQorBecomeHostorContact(
                                lang,
                                isFAQ ? "faq" : "contact",
                                userIdForGTM,
                            );
                        };
                        return <MenuItem key={key} entry={value as MenuEntry} transparent={transparent} lang={lang} />;
                    })}
                    <UserMenu
                        lang={lang}
                        transparent={transparent}
                        isGuestPanelNavigation={isGuestPanelNavigation}
                        pageType={pageType}
                    />
                </div>
            </div>
            <div
                className={classNames(
                    `w-full flex pt-2 pb-8 justify-center mx-auto`,
                    `${!showSearchBar ? " hidden " : " "}`,
                    `${isGuestPanelNavigation ? "bg-black" : "bg-white"}`,
                )}
            >
                <SearchBar
                    defaultProps={searchBarProps}
                    onCloseMobileModal={() => setIsMobileSearchOpen(false)}
                    isMobileModalOpen={isMobileSearchOpen}
                />
                <Button
                    onClick={handleCloseSearchBar}
                    variant={isGuestPanelNavigation ? "white" : "transparent"}
                    className="ml-1 px-4 py-2"
                >
                    <FontAwesomeIcon icon={faClose} size="lg" />
                </Button>
            </div>

            {/* MOBILE MENU */}
            <div
                className={`flex lg:hidden gap-2 items-center justify-between font-bold w-full select-none z-20 ${isGuestPanelNavigation ? "bg-black" : ""
                }`}
            >
                <div
                    className="block items-center align-middle py-5 px-5 cursor-pointer active:bg-primary-500 transition-colors duration-150 ease-in no-highlight flex-none w-16"
                    onClick={() => handleToggleMobileMenu("menu")}
                    ref={refMenuButton}
                >
                    <FontAwesomeIcon
                        icon={faBars}
                        size="lg"
                        className={getIconColor(transparent || isGuestPanelNavigation)}
                    />
                </div>

                {!showSearchInput ? (
                    <Link
                        href={getUrl(Urls.home.index, lang)}
                        className={`block flex-none ${transparent ? "" : "text-primary-500"}`}
                    >
                        {getAppLogo(transparent || isGuestPanelNavigation, 25, 124)}
                    </Link>
                ) : (
                    <div className="lg:w-full w-full xl:hidden sm:w-1/2">
                        <Input
                            placeholder={tSearch("where-to-go") ?? ""}
                            className="w-full"
                            onClick={() => setIsMobileSearchOpen(true)}
                            readOnly
                        />
                    </div>
                )}
                <div
                    className="block items-center align-middle py-5 px-5 cursor-pointer active:bg-primary-500 transition-colors duration-150 ease-in no-highlight flex-none"
                    onClick={() => handleToggleMobileMenu("user")}
                    ref={refUserMenuButton}
                >
                    {auth.isLoggedIn ? (
                        <>
                            {auth.userInfo.profilePicture ? (
                                <UserProfilePicture
                                    image={auth.userInfo.profilePicture}
                                    size={32}
                                    className="border-2 w-6 h-6 lg:w-8 lg:h-8 sm:border-2 rounded-full border-green-500"
                                />
                            ) : (
                                <div
                                    className="flex border-2 min-w-[38px] w-10 h-10 sm:border-2 rounded-full border-green-500">
                                    <div className="m-auto mx-auto my-auto">{fetchInitials()}</div>
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="flex min-w-[38px] w-10 h-10">
                            <div className="m-auto mx-auto my-auto">
                                <FontAwesomeIcon icon={faCircleUser} size="xl" />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Transition
                show={showMobileMenu}
                enter="transition-transform ease-in-out duration-200 transform"
                enterFrom="-translate-y-2"
                enterTo="translate-y-0"
                leave="transition-transform ease-in-out duration-50 transform"
                leaveFrom="translate-y-0"
                leaveTo="-translate-y-2"
            >
                <div
                    className="lg:hidden w-full flex flex-col bg-white  text-black font-bold z-10"
                    ref={refMenu as React.LegacyRef<HTMLDivElement>}
                >
                    {!isGuestPanelNavigation && mobileMenuType === "menu" && (
                        <Text
                            onClick={handleOpenSearchModal}
                            className="hover:bg-cyan-500 hover:text-white hover:cursor-pointer w-full block p-4 active:bg-cyan-500 active:text-white no-highlight"
                        >
                            <div className="inline-block h-20px w-8 text-center">
                                <FontAwesomeIcon icon={faMagnifyingGlass} className="m-auto" />
                            </div>
                            {tSearch("button-title") as string}
                        </Text>
                    )}
                    {isGuestPanelNavigation &&
                        fetchMobileMenu()
                            .reduce<MenuEntry[]>((p, c) => (Array.isArray(c) ? p.concat(c) : [...p, c]), [])
                            .map((e: MenuEntry) => (
                                <GenericMenuItem
                                    isMobile
                                    onClickHandler={e?.onClickHandler}
                                    key={e.key + "-mobile"}
                                    url={e.url}
                                    text={e.key}
                                    lang={lang}
                                    itemIcon={e?.menuIcon}
                                    hasSubMenu={e.hasSubMenu}
                                    subMenuItems={e.subMenuItems}
                                />
                            ))}
                    {mobileMenuType === "menu" && (
                        <>
                            <Link
                                href={getUrl(Urls.faq.guest, lang)}
                                onClick={() => setShowMobileMenu(false)}
                                className="hover:bg-cyan-500 hover:text-white w-full block p-4 bg-white text-black active:bg-cyan-500 select-none no-highlight"
                            >
                                <div className="inline-block h-20px w-8 text-center">
                                    <FontAwesomeIcon icon={faQuestion} className="m-auto" />
                                </div>
                                <Text className="whitespace-nowrap">{t("faq")}</Text>
                            </Link>
                            <Link
                                href={getUrl(Urls.contactUs.index, lang)}
                                onClick={() => {
                                    GoogleTagManagerEvents.clickFAQorBecomeHostorContact(lang, "contact", userIdForGTM);
                                    setShowMobileMenu(false);
                                }}
                                className="hover:bg-cyan-500 hover:text-white w-full block p-4 bg-white text-black active:bg-cyan-500 select-none no-highlight"
                            >
                                <div className="inline-block h-20px w-8 text-center">
                                    <FontAwesomeIcon icon={faAt} className="m-auto" />
                                </div>
                                <Text className="whitespace-nowrap">{t("contact-us")}</Text>
                            </Link>
                            <Link
                                href="#"
                                className="hover:bg-cyan-500 hover:text-white w-full block p-4 active:bg-cyan-500 active:text-white select-none no-highlight"
                                onClick={handleOpenLanguageModal}
                            >
                                <div className="inline-block h-20px w-8 text-center">
                                    <LanguageSelectorIcon
                                        flagIcon={currentLangaugeData.flagIcon}
                                        name={currentLangaugeData.name}
                                        dark={true}
                                    />
                                </div>
                                <Text className="whitespace-nowrap ml-2">{t("language")}</Text>
                            </Link>
                        </>
                    )}
                    {!auth.isLoggedIn && mobileMenuType === "user" && (
                        <>
                            <Link
                                href="#"
                                onClick={(e) => {
                                    GoogleTagManagerEvents.clickOnGoToLogin(lang, "header");
                                    e?.preventDefault();
                                    openLoginModal(true);
                                }}
                                className="hover:bg-cyan-500 hover:text-white hover:cursor-pointer w-full block p-4 bg-white text-black active:bg-cyan-500 select-none no-highlight"
                            >
                                <Text className="whitespace-nowrap">{t("login")}</Text>
                            </Link>
                            <Link
                                href={registerUrl}
                                className="hover:bg-cyan-500 hover:text-white hover:cursor-pointer w-full block p-4 bg-white text-black active:bg-cyan-500 select-none no-highlight"
                                onClick={() => {
                                    GoogleTagManagerEvents.gotoRegisterPageClick(lang, "header");
                                }}
                            >
                                <Text className="whitespace-nowrap">{t("register")}</Text>
                            </Link>
                           {/* <Link
                                href={getUrl(Urls.becomeAHost.index, lang)}
                                onClick={() =>
                                    GoogleTagManagerEvents.clickFAQorBecomeHostorContact(
                                        lang,
                                        "become_a_partner",
                                        userIdForGTM,
                                    )
                                }
                                className="w-full block p-4 bg-primary-500 text-white active:bg-cyan-500 select-none no-highlight"
                            >
                                <Text className="whitespace-nowrap">{t("become-a-host")}</Text>
                            </Link>*/}
                        </>
                    )}
                    {!isGuestPanelNavigation &&
                        auth.isLoggedIn &&
                        mobileMenuType === "user" &&
                        fetchMobileMenu()
                            .reduce<MenuEntry[]>((p, c) => (Array.isArray(c) ? p.concat(c) : [...p, c]), [])
                            .map((e: MenuEntry) => (
                                <GenericMenuItem
                                    isMobile
                                    key={e.key + "-mobile"}
                                    url={e.url}
                                    text={e.key}
                                    onClickHandler={e?.onClickHandler}
                                    lang={lang}
                                    itemIcon={e?.menuIcon}
                                    hasSubMenu={e.hasSubMenu}
                                    subMenuItems={e.subMenuItems}
                                />
                            ))}
                </div>
            </Transition>
        </header>
    );
};

export default MenuClient;
