"use client";
import { AnonymousUserMenuEntries } from "@/lib/constants/menuEntries";
import { authAtom, isLoginModalShownAtom } from "@/lib/jotai/auth/authStore";
import { LanguageParams } from "@/lib/types/PageProps";
import { useAtom, useSetAtom } from "jotai";
import { Popover } from "@headlessui/react";
import { PageType } from "@/lib/types/Page";
import { useTranslation } from "@/lib/i18n/client";
import { getUrl, Urls } from "@/lib/constants/urls";
import { userLogOut } from "@/lib/utils/logout";
import MenuItem from "./MenuItem";
import Text from "../Text";
import Link from "next/link";
import UserProfilePicture from "../UserProfilePicture";
import useUserInfo from "@/lib/hooks/useUserInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser } from "@fortawesome/pro-light-svg-icons";
import { GoogleTagManagerEvents } from "@/lib/utils/googleTagEvents";

type UserMenuProps = {
    children?: React.ReactElement;
    transparent?: boolean;
    pageType: PageType;
    isGuestPanelNavigation?: boolean;
} & LanguageParams;

const UserMenu = ({ lang,
                      // pageType,
                      transparent = false,
                      children,
                      isGuestPanelNavigation = false }: UserMenuProps) => {
    const openLoginModal = useSetAtom(isLoginModalShownAtom);
    const [auth, setAuth] = useAtom(authAtom);
    // const { t } = useTranslation(lang, "menu");
    const { t: tDesktopMenu } = useTranslation(lang, "desktop-menu");
    useUserInfo();

    const loginEntry = AnonymousUserMenuEntries[0];
    const registerEntry = AnonymousUserMenuEntries[1];

    loginEntry.onClick = () => {
        GoogleTagManagerEvents.clickOnGoToLogin(lang, "header");
        openLoginModal(true);
    };

    registerEntry.onClick = () => GoogleTagManagerEvents.gotoRegisterPageClick(lang, "header");

    const fetchInitials = () => {
        const firstInitial = Array.from(auth.userInfo.firstName ?? "")[0] ?? "";
        const lastInitial = Array.from(auth.userInfo.lastName ?? "")[0] ?? "";
        return firstInitial + lastInitial;
    };

    return (
        <>
            {!auth.isLoggedIn ? (
                [
                    <MenuItem
                        entry={loginEntry}
                        lang={lang}
                        transparent={transparent || isGuestPanelNavigation}
                        key={loginEntry.key}
                    />,
                    <MenuItem
                        entry={registerEntry}
                        lang={lang}
                        transparent={transparent || isGuestPanelNavigation}
                        key={registerEntry.key}
                    />,
                    /*<div key="become-a-host" className="border-y-4 border-transparent">
                        <LinkButton
                            href={getUrl(Urls.becomeAHost.index, lang)}
                            variant={pageType === "home" ? "orange" : "transparent"}
                            onClick={() =>
                                GoogleTagManagerEvents.clickFAQorBecomeHostorContact(
                                    lang,
                                    "become_a_partner",
                                    auth.isLoggedIn ? auth.userInfo._id : undefined,
                                )
                            }
                            className={`my-5 mx-0 px-8 h-12 ${pageType !== "home" &&
                                "hover:bg-primary-500 hover:text-white hover:border-primary-500 transition-colors ease-in"
                                }`}
                        >
                            {t("become-a-host")}
                        </LinkButton>
                    </div>*/
                ]
            ) : (
                <div className="flex flex-col justify-center">
                    <Popover className="relative">
                        <Popover.Button>
                            <div
                                className="flex items-center align-middle cursor-pointer active:bg-primary-500 transition-colors duration-150 ease-in no-highlight gap-3">
                                <Text
                                    as="span"
                                    className={`pr-1 ${transparent || isGuestPanelNavigation ? "text-white" : "text-black"
                                    }`}
                                >
                                    {auth.userInfo.firstName}
                                </Text>

                                {auth.isLoggedIn ? (
                                    <>
                                        {auth.userInfo.profilePicture ? (
                                            <UserProfilePicture
                                                image={auth.userInfo.profilePicture}
                                                size={32}
                                                className="border-2 w-6 h-6 lg:w-8 lg:h-8 sm:border-2 rounded-full border-green-500"
                                            />
                                        ) : (
                                            <div
                                                className="flex border-2 min-w-[48px] w-12 h-12 sm:border-2 rounded-full border-green-500">
                                                <div className="m-auto mx-auto my-auto">{fetchInitials()}</div>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <FontAwesomeIcon icon={faCircleUser} size="2xl" />
                                )}
                            </div>
                        </Popover.Button>

                        <Popover.Panel className="absolute z-10 -left-32 p-2 text-black w-56">
                            <div className="flex flex-col items-stretch bg-white text-left rounded-md shadow-md">
                                <Link
                                    className="inline-block hover:bg-cyan-500 hover:text-white align-middle rounded-t-md"
                                    href={getUrl(Urls.userProfile.index, lang)}
                                >
                                    <div className="w-full h-full  p-4">{tDesktopMenu("Profile")}</div>
                                </Link>
                                <Link
                                    className="inline-block hover:bg-cyan-500 hover:text-white"
                                    href={getUrl(Urls.reservations.index, lang)}
                                >
                                    <div className="w-full h-full p-4">{tDesktopMenu("Reservations")}</div>
                                </Link>
                                <Link
                                    className="inline-block hover:bg-cyan-500 hover:text-white"
                                    href={getUrl(Urls.invoices.index, lang)}
                                >
                                    <div className="w-full h-full p-4">{tDesktopMenu("Invoices")}</div>
                                </Link>
                                <Link
                                    className="inline-block hover:bg-cyan-500 hover:text-white"
                                    href={getUrl(Urls.messages.index, lang)}
                                >
                                    <div className="w-full h-full p-4">{tDesktopMenu("Messages")}</div>
                                </Link>
                                <div className="hover:bg-cyan-500 hover:text-white rounded-b-md">
                                    <button
                                        className="text-left w-full p-4"
                                        onClick={() => userLogOut(setAuth, auth)}
                                    >
                                        {tDesktopMenu("Log out")}
                                    </button>
                                </div>
                            </div>
                        </Popover.Panel>
                    </Popover>
                </div>
            )}
            {children}
        </>
    );
};

export default UserMenu;
